/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: sans-serif;
}

/* ------------------------------------ */
a {
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

/* ------------------------------------ */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/* ------------------------------------ */
input {
  display: block;
  outline: none;
  border: none !important;
}

textarea {
  display: block;
  outline: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

/* ------------------------------------ */
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
  [ Utiliti ]*/

/*//////////////////////////////////////////////////////////////////
  [ Table ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-table100 {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
}

.wrap-table100 {
  width: 120rem;
  max-width: 98vw;
}

table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
table * {
  position: relative;
}
table td,
table th {
  border-left: 1px solid #e5e5e5;
}
table thead tr {
  height: 60px;
  background: #36304a;
}
table tbody tr {
  height: 50px;
}
table tbody tr:last-child {
  border: 0;
}
table td,
table th {
  text-align: left;
}
table td.l,
table th.l {
  text-align: right;
}
table td.c,
table th.c {
  text-align: center;
}
table td.r,
table th.r {
  text-align: center;
}

.table100-head th {
  font-family: OpenSans-Regular;
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset;
}

tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

tbody tr {
  font-family: OpenSans-Regular;
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
  font-weight: unset;
}

tbody tr:hover {
  color: #555555;
  background-color: #f5f5f5;
  cursor: pointer;
}

.column1 {
  width: 150px;
  text-align: center;
  white-space: nowrap;
}

.column2 {
  width: 100px;
  padding-left: 0px;
  text-align: center;
}

.column3 {
  width: 100px;
  text-align: center;
}

.column5 {
  width: 100px;
  text-align: center;
}

.column4 {
  width: 100px;
  text-align: center;
  white-space: nowrap;
}

.column6 {
  width: 80px;
  text-align: center;
  vertical-align: middle;
}

.column8 {
  width: 100px;
  text-align: center;
  white-space: nowrap;
}

.column5 {
  width: 100px;
  text-align: center;
  white-space: nowrap;
}

/* @media screen and (max-width: 992px) {
  table {
    display: block;
  }
  table > *,
  table tr,
  table td,
  table th {
    display: block;
  }
  table thead {
    display: none;
  }
  table tbody tr {
    height: auto;
    padding: 37px 0;
  }
  table tbody tr td {
    padding-left: 40% !important;
    margin-bottom: 24px;
    text-align: left !important;
  }
  table tbody tr td:last-child {
    margin-bottom: 0;
  }
  table tbody tr td:before {
    font-size: 14px;
    color: #999999;
    line-height: 1.2;
    font-weight: unset;
    position: absolute;
    width: 40%;
    left: 30px;
    top: 0;
  }
  table tbody tr td:nth-child(1):before {
    content: "STT";
  }
  table tbody tr td:nth-child(2):before {
    content: "Mã vật tư";
  }
  table tbody tr td:nth-child(3):before {
    content: "Quy cách";
  }
  table tbody tr td:nth-child(4):before {
    content: "Lô";
  }
  table tbody tr td:nth-child(5):before {
    content: "Phân loại";
  }
  table tbody tr td:nth-child(6):before {
    content: "Tồn khả dụng";
  }
  table tbody tr td:nth-child(7):before {
    content: "Kho";
  }
  table tbody tr td:nth-child(8):before {
    content: "ĐVT";
  }
  table tbody tr td:nth-child(9):before {
    content: "Tồn kho";
  }

  .column3,
  .column5,
  .column4 {
    text-align: left;
  }

  .column3,
  .column5,
  .column4,
  .column1,
  .column1,
  .column2 {
    width: 100%;
  }

  tbody tr {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .container-table100 {
    padding-left: 15px;
    padding-right: 15px;
  }
} */

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  position: sticky; /* Giữ header khi cuộn */
  top: 0; /* Vị trí cố định ở đầu */
  z-index: 1; /* Đảm bảo header nằm trên các phần tử khác */
}
