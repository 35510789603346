:root {
  --rad: 0.7rem;
  --dur: 0.3s;
  --color-dark: #2f2f2f;
  --color-light: #fff;
  --color-brand: #57bd84;
  --font-fam: "Lato", sans-serif;
  --height: 5rem;
  --btn-width: 12rem;
  --btn-width-mobile: 10rem;
  --bez: cubic-bezier(0, 0, 0.43, 1.49);
}

body {
  background: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
html {
  box-sizing: border-box;
  height: 100%;
  font-size: 10px;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

.form {
  position: relative;
  background: var(--color-brand);
  border-radius: var(--rad);
  flex-grow: 1;
}
.search-bar input,
.search-bar button:not(.filter-container) {
  height: var(--height);
  font-family: var(--font-fam);
  border: 0;
  color: var(--color-dark);
  font-size: 1.8rem;
}
.search-bar input[type="search"] {
  outline: 0;
  width: 100%;
  background: var(--color-light);
  padding: 0 1.6rem;
  border-radius: var(--rad);
  appearance: none;
  transition: all var(--dur) var(--bez);
  transition-property: width, border-radius;
  z-index: 1;
  position: relative;
}
.search-bar button:not(.filter-container) {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--btn-width);
  font-weight: bold;
  background: var(--color-brand);
  border-radius: 0 var(--rad) var(--rad) 0;
}
.search-bar input:not(:placeholder-shown) {
  border-radius: var(--rad) 0 0 var(--rad);
  width: calc(100% - var(--btn-width));
  + button {
    display: block;
  }
}
/* @media only screen and (max-width: 768px) {
  button {
    width: var(--btn-width-mobile);
  }
  input:not(:placeholder-shown) {
    width: calc(100% - var(--btn-width-mobile));
  }
} */

.search-bar label {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 120rem;
  max-width: 90vw;
}
