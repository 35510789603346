/* src/App.css */

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: #333;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  background-color: #007bff;
  padding: 20px;
  text-align: center;
}

.app-title {
  margin: 0;
  color: #fff;
  font-size: 2rem;
}

.app-main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* @media screen and (max-width: 992px) {
  .app-main {
    padding: 0px;
  }
} */

.app-footer {
  color: white;
  text-align: center;
  padding: 10px;
}

/* @media (max-width: 600px) {
  .app-title {
    font-size: 1.5rem;
  }

  .search-bar {
    flex-direction: column;
  }

  .search-input,
  .search-button {
    border-radius: 4px;
  }

  .search-input {
    border-right: 2px solid #007bff;
    margin-bottom: 10px;
  }

  .search-button {
    border-radius: 4px;
  }
} */

/* Overlay cho Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Màu nền tối để nhấn mạnh modal */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Đảm bảo modal ở trên cùng */
  animation: fadeIn 0.3s ease-out;
}

/* Hiệu ứng FadeIn cho Overlay */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.filter-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
  color: white;
}

.filter-container {
  margin-left: 10px;
  padding: 10px;
}

/* Overlay của modal */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Màu nền bán trong suốt */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Đảm bảo modal nằm trên các phần tử khác */
}

/* Nội dung modal */
.custom-modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  animation: slideIn 0.3s ease-out;
}

/* Thêm hiệu ứng khi mở modal */
@keyframes slideIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Tiêu đề modal */
.custom-modal-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

/* Body của modal */
.custom-modal-body {
  margin-bottom: 20px;
}

/* Nhóm checkbox */
.custom-checkbox-group {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
}

.custom-checkbox-item {
  display: flex;
  font-size: 1.5em;
  text-align: center;
}

/* Nút checkbox */
.custom-checkbox {
  margin-right: 10px;
  width: 1.5em;
  height: 1.5em;
}

/* Nút trong modal */
.custom-btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
}

.custom-btn:hover {
  opacity: 0.8;
}

/* Style cho nút áp dụng và đóng trong modal */
.custom-modal-btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  outline: none;
  text-align: center;
}

/* Nút Áp dụng */
.custom-modal-btn.apply-btn {
  background-color: #007bff;
  color: white;
}

.custom-modal-btn.apply-btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px); /* Hiệu ứng nổi khi hover */
}

.custom-modal-btn.apply-btn:active {
  background-color: #004085;
  transform: translateY(2px); /* Hiệu ứng khi nhấn */
}

/* Nút Đóng */
.custom-modal-btn.close-btn {
  background-color: #6c757d;
  color: white;
}

.custom-modal-btn.close-btn:hover {
  background-color: #5a6268;
  transform: translateY(-2px); /* Hiệu ứng nổi khi hover */
}

.custom-modal-btn.close-btn:active {
  background-color: #343a40;
  transform: translateY(2px); /* Hiệu ứng khi nhấn */
}

/* Nút nhóm */
.custom-modal-btn-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
